import * as React from "react";
import Button from "@material-ui/core/Button";
import moment from "moment";
import {
  showNotification as showNotificationAction,
  useRefresh,
  fetchUtils,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiURL, httpClient } from "../http";
import Tooltip from "@material-ui/core/Tooltip";

function SimpleDialog(props) {
  const { open, setClicked, id, record } = props;

  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [loading, setLoading] = React.useState(false);

  const refresh = useRefresh();
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent dividers>
        <div style={{ overflow: "hidden" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Calendar
              disableFuture
              date={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
              }}
              cancelLabel={true}
              firstDayOfWeek={0}
            />
          </MuiPickersUtilsProvider>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setClicked(false);
          }}
        >
          Anuluj
        </Button>

        {!loading && (
          <Button
            onClick={() => {
              setSelectedDate(moment());
            }}
          >
            Dziś
          </Button>
        )}
          <Tooltip title="Ustaw datę księgowania przelewu">

        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            refresh();
            setLoading(true);

            httpClient(`${apiURL}/orders/${id}/payment_date_ack`, {
              method: "POST",
              body: JSON.stringify({
                payment_date_ack: selectedDate.format("YYYY-MM-DD"),
              }),
            })
              .then(() => {
                setClicked(false);
              })
              .catch((e) => {
                showNotificationAction(e, "error");
                setClicked(false);
              });
          }}
        >
          {loading && <CircularProgress size={16} />}
          {!loading && <>Ustaw [{selectedDate.format("YYYY-MM-DD")}]</>}
        </Button>
          </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

export default function SetPaymentDateButton({ action, record }) {
  const [clicked, setClicked] = React.useState(false);

  return (
    <>
      <SimpleDialog
        key={`calendar${record.id}`}
        onClose={() => {
          setClicked(false);
        }}
        setClicked={setClicked}
        id={record.id}
        record={record}
        open={clicked}
      />
      <Button
        style={{
          fontSize: "10px",
          padding: "4px 10px",
          minWidth: "32px",
        }}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setClicked(true);
        }}
        disabled={false}
      >
        {action.label}
      </Button>
    </>
  );
}

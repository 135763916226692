import * as React from "react";
import Button from "@material-ui/core/Button";
import CalendarIcon from "@material-ui/icons/CalendarViewDaySharp";
import {
  showNotification as showNotificationAction,
  useRefresh,
} from "react-admin";
import { Api } from "../http";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red, green, yellow } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";

export default function SetPaymentDateButton({ action, record, status }) {
  const [clicked, setClicked] = React.useState(false);
  const refresh = useRefresh();

  const color = {
    ended: red[700],
    canceled: red[700],
    correction: red[700],
  };
  return (
    <>
      {!clicked && (
        <Tooltip title={action ? action.tooltip || '' : ''}>
          <Button
            style={{
              fontSize: "10px",
              padding: "4px 10px",
              minWidth: "32px",
            }}
            color="primary"
            size="small"
            variant="contained"
            onClick={() => {
              setClicked(true);
              Api.patchOrderItem(record.id, [
                  { op: "replace", value: 'artist_to_pay', path: "/status_item" },
                  ])
                .then(() => {
                  refresh();
                  setClicked(false);
                })
                .catch((e) => {
                  showNotificationAction(e, "error");
                  setClicked(false);
                });
            }}
            disabled={false}
          >
            {action.label}
          </Button>
        </Tooltip>
      )}
      {clicked && <CircularProgress size={16} />}
    </>
  );
}

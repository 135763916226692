
import * as React from "react";
import Button from "@material-ui/core/Button";
import moment from "moment";
import {
    useNotify,
} from "react-admin";
import DateFnsUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import Grid from "@material-ui/core/Grid";
import {apiURL, httpClient} from "../http";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
export default function Settings() {
  const notify = useNotify();
  const classes = useStyles();
    const [selectedDateStart, setSelectedDateStart] = React.useState(moment().startOf('month'));
    const [selectedDateEnd, setSelectedDateEnd] = React.useState(moment().endOf('month'));
    const [clicked, setClicked] = React.useState(false);
    const [sampleData, setSampleData] = React.useState('');


    const handleDateChange = (date) => {
        setSelectedDateStart(date);
    };


    const handleDateChangeEnd = (date) => {
        setSelectedDateEnd(date);
    };



    const handleDownloadClick = () => {
        httpClient(`${apiURL}/export/monthly?_from=${selectedDateStart.format('YYYY-MM-DD')}&_to=${selectedDateEnd.format('YYYY-MM-DD')}`, {
            method: "GET",
        })
            .then((response) => {
                const blob = new Blob([response.body], {
                    type: "text/csv;charset=utf-8",
                });

                saveAs(blob, `monthly-export-${selectedDateStart.format('YYYYMMDD')}${selectedDateEnd.format('YYYYMMDD')}.csv`);

                setClicked(false);
            })
            .catch((e) => {
                if(e.status === 404) {
                    notify("Brak zamówień do eksportu");
                }
                else {
                    notify(e?.body?.detail || "Błąd");
                }

                setClicked(false);
            });
    };

    return (
    <>
      <div className={classes.root}>
          <Backdrop
              className={classes.backdrop}
              open={clicked}
              onClick={() => {}}
          >
              <CircularProgress color="inherit" />
          </Backdrop>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container>
              <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="YYYY-MM-DD"
                  margin="normal"
                  id="date-picker-start"
                  label="Początek"
                  value={selectedDateStart}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                      'aria-label': 'change date',
                  }}
              />

              <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="YYYY-MM-DD"
                  margin="normal"
                  id="date-picker-end"
                  label="Koniec"
                  value={selectedDateEnd}
                  onChange={handleDateChangeEnd}
                  KeyboardButtonProps={{
                      'aria-label': 'change date',
                  }}
              />
              <Button color="primary"   variant="contained" onClick={handleDownloadClick} >Pobierz</Button>


          </Grid>
      </MuiPickersUtilsProvider>


      </div>
    </>
  );
}



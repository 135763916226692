import * as React from "react";
import Button from "@material-ui/core/Button";
import CalendarIcon from "@material-ui/icons/CalendarViewDaySharp";
import {
  showNotification as showNotificationAction,
  useRefresh,
  fetchUtils,
} from "react-admin";
import { apiURL, httpClient } from "../http";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";

export default function SetPaymentDateButton({ action, record }) {
  const [clicked, setClicked] = React.useState(false);
  const refresh = useRefresh();
  return (
    <>
      {!clicked && (
        <Tooltip title="Potwierdź otrzymanie faktury od Artysty">
          <Button
            style={{
              fontSize: "10px",
              padding: "4px 10px",
              minWidth: "32px",
              color: "white",
              backgroundColor: red[700],
            }}
            size="small"
            variant="contained"
            onClick={() => {
              setClicked(true);
              httpClient(`${apiURL}/orders/${record.id}?_method=PATCH`, {
                method: "POST",
                body: JSON.stringify([
                  { op: "replace", value: 1, path: "/artist_invoice_ack" },
                ]),
              })
                .then(() => {
                  refresh();
                  setClicked(false);
                })
                .catch((e) => {
                  showNotificationAction(e, "error");
                  setClicked(false);
                });
            }}
            disabled={false}
          >
            FA OK
          </Button>
        </Tooltip>
      )}
      {clicked && <CircularProgress size={16} />}
    </>
  );
}

const tabs = {


  active: {
    row: 2,
    id: "active",
    tooltip: "Aktywne zamówienia",
    name: "A",
    filter: { tab: "active" },
  },
  new: {
    row: 2,
    id: "new",
    tooltip: "Nowe zamówienia",
    name: "NOWE",
    filter: { tab: "new" },
  },
  transport_artist: {
    row: 1,
    id: "transport_artist",
    tooltip: "Zamów kuriera do artysty",
    name: "KA",
    filter: { tab: "ready_for_artist_transport" },
  },
  // transport_artist_manual: {
  //   row: 1,
  //   id: "transport_artist_manual",
  //   tooltip: "Zamów kuriera do artysty ręcznie",
  //   name: "KAR",
  //   filter: { tab: "manual_artist_transport" },
  // },
  transport_artist_waiting: {
    row: 1,
    id: "transport_artist_waiting",
    tooltip: "Prace czekające na wysyłkę u artysty",
    name: "DRA",
    filter: { tab: "transport_artist_waiting" },
  },

  transport_artist_now: {
    row: 1,
    id: "transport_artist_now",
    tooltip: "Prace w drodze od artysty",
    name: "WDR",
    filter: { tab: "transport_artist_now" },
  },
  transport_buyer: {
    row: 1,
    id: "transport_buyer",
    tooltip: "Zamów kuriera do klienta",
    name: "KK",
    filter: { tab: "ready_for_buyer_transport" },
  },
  transport_buyer_waiting: {
    row: 1,
    id: "transport_buyer_waiting",
    tooltip: "Prace w drodze do klienta",
    name: "DRK",
    filter: { tab: "transport_buyer_waiting" },
  },
  // transport_buyer_manual: {
  //   row: 1,
  //   id: "transport_buyer_manual",
  //   tooltip: "Zamów kuriera do klienta ręcznie",
  //   name: "KKR",
  //   filter: { tab: "manual_buyer_transport" },
  // },
  to_pay: {
    row: 2,
    id: "to_pay",
    tooltip: "Nierozliczone",
    name: "NR",
    filter: { tab: "to_pay" },
  },
  pay_on_delivery: {
    row: 2,
    id: "pay_on_delivery",
    tooltip: "Rozl POBRANIE",
    name: "POB",
    filter: { tab: "pay_on_delivery" },
  },
  pay_online: {
    row: 2,
    id: "pay_online",
    tooltip: "Rozl PRZ24",
    name: "P24",
    filter: { tab: "pay_online" },
  },
  pay_bank: {
    row: 2,
    id: "pay_bank",
    tooltip: "Rozl Przelew",
    name: "PRZ",
    filter: { tab: "pay_bank" },
  },
  pay_card: {
    row: 2,
    id: "pay_card",
    tooltip: "Rozl Karty ",
    name: "KART",
    filter: { tab: "pay_card" },
  },
  in_bank: {
    row: 2,
    id: "in_bank",
    tooltip: "Czeka na export mBANK", // 18 dni
    name: "BANK",
    filter: { tab: "in_bank" },
  },
  to_bank: {
    row: 2,
    id: "to_bank",
    tooltip: "Prace do zapłaty", // 18 dni
    name: "ZAP",
    filter: { tab: "to_bank" },
  },
  // bank: {
  //   row: 2,
  //   id: "bank",
  //   tooltip: "Przelewy bankowe wyeksportowane",
  //   name: "MB",
  //   filter: { tab: "in_bank" },
  // },
  urgent: {
    row: 1,
    id: "urgent",
    tooltip: "Pilne",
    name: "P!",
    // icon: <NewReleasesIcon style={{ color: red[200] }} />,
    filter: { tab: "urgent" },
  },
  returns: {
    row: 1,
    id: "returns",
    tooltip: "Zwroty",
    name: "ZW",
    filter: { tab: "returns" },
  },
  complaint: {
    row: 1,
    id: "complaint",
    tooltip: "Reklamacje",
    name: "RKL",
    filter: { tab: "complaint" },
  },
  fkt: {
    row: 1,
    id: "fkt",
    tooltip: "Faktura",
    name: "FKT",
    filter: { tab: "fkt" },
  },
  invoice: {
    row: 1,
    id: "invoice",
    tooltip: "Faktura - Zaznaczone w notatkach",
    name: "FKI",
    filter: { tab: "invoice_info" },
  },

  all: {
    row: 1,
    id: "all",
    tooltip: "Wszystkie zamówienia",
    name: "W",
    filter: {},
  },
};

export default tabs;

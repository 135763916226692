import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import { Card, CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import PrivateNotesForm from "../Forms/PrivateNotesForm";



export const MARKS = {
    "is_urgent": "Pilne",
    is_return: "Zwrot",
    is_complaint: "Reklamacja",
    is_invoice_info: "Faktura",
    is_artist_address_note: "Adr. artysty",
    is_buyer_address_note: "Adr. nabywcy",
    "mark_is_other": "Inne",
    "mark_is_deadline": "Termin",
    "ignore_notes": "Ignoruj notatki",
};



export function NotesTab(props) {
  return (
    <>
      <FormGroup row>
          {Object.entries(MARKS).map(
              ([key, val]) => (
                  <FormControlLabel
                      key={key}
                      style={{ fontSize: "10px" }}
                      control={
                          props.switchLoading[key] ? (
                              <CircularProgress size={16} />
                          ) : (
                              <Switch
                                  checked={props.record[key]}
                                  onChange={props.onChange}
                                  name={key}
                                  size="small"
                              />
                          )
                      }
                      labelPlacement="start"
                      label={val}
                  />
              )

          )}
      </FormGroup>

      <>
        <PrivateNotesForm
          orderItemId={props.controllerProps.record.id}
          initialValues={{
            private_notes: props.controllerProps.record.private_notes,
          }}
        />

        <Box m="0 0 1em 1em">
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Informacje od klienta:
              </Typography>
              <Box display="flex">
                <small>{props.controllerProps.record.order.info}</small>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box m="0 0 1em 1em">
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Dedykacja:
              </Typography>
              <Box display="flex">
                <small>{props.controllerProps.record.order.dedication}</small>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </>
    </>
  );
}

NotesTab.propTypes = {
  switchLoading: PropTypes.shape({}),
  record: PropTypes.any,
  onChange: PropTypes.func,
  controllerProps: PropTypes.any,
};

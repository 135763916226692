import * as React from "react";
import { Fragment, useState } from "react";
import {
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import { httpClient, apiURL } from "../http";

import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          style={{ color: "white" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ResetViewsButton = ({ selectedIds }) => {
  const [open, setOpen] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();
  const unselect = useUnselectAll("orders");

  const handleClick = () => {
    setOpen(true);

    const promises = selectedIds.map((id) =>
      httpClient(`${apiURL}/orders/${id}/send_transport_to_artist`, {
        method: "POST",
        body: JSON.stringify({}),
      })
    );

    Promise.all(promises).then(() => {
      refresh();
        unselect();
      setOpen(false);
    });
  };

  return (
    <Fragment>
      <Button label="Zamów Kurier Artysta" onClick={handleClick} />
      <Backdrop className={classes.backdrop} open={open} onClick={() => {}}>
        <CircularProgress size={60} />
      </Backdrop>
    </Fragment>
  );
};
export default ResetViewsButton;

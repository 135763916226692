// in src/MyLayout.js
import * as React from "react";
import { forwardRef } from "react";
import {Layout, MenuItemLink, usePermissions} from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import env from "../env";
const ConfigurationMenu = forwardRef(({ onClick }, ref) => {
    const { permissions } = usePermissions();

    const isAdmin = permissions && permissions.includes("ROLE_ADMIN");

    return (
        <>
            <MenuItemLink
                ref={ref}
                to="/dashboard"
                primaryText="Start"
                leftIcon={<DashboardIcon />}
                onClick={onClick} // close the menu on click
            />
            <MenuItemLink
                ref={ref}
                to="/orders"
                primaryText="Zamówienia"
                leftIcon={<ShoppingCartIcon />}
                onClick={onClick} // close the menu on click
            />
            {isAdmin && (
            <MenuItemLink
                ref={ref}
                to="/reports"
                primaryText="Eksport"
                leftIcon={<InsertDriveFileIcon />}
                onClick={onClick} // close the menu on click
            />)}
            <hr/>
            {isAdmin && (
            <MenuItemLink
                ref={ref}
                to="/settings"
                primaryText="Ustawienia"
                leftIcon={<SettingsIcon />}
                onClick={onClick} // close the menu on click
            />)}
            <MenuItemLink
                ref={ref}
                to="/statuses"
                primaryText="Statusy"
                leftIcon={<TrendingFlatIcon />}
                onClick={onClick} // close the menu on click
            />

        </>
    )
});

const MyLayout = (props) => (
  <Layout
    {...props}
    // appBar={MyAppBar}
    menu={ConfigurationMenu}
    // notification={MyNotification}
  />
);

export default MyLayout;

import * as React from "react";
import {
  showNotification as showNotificationAction,
  TextField,
  useEditController,
  useRefresh,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import "date-fns";
//
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DrawerToolbar from "./DrawerToolbar";
import Chip from "@material-ui/core/Chip";
import { green, red, yellow } from "@material-ui/core/colors";
import { Card, CardContent } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { apiURL, httpClient } from "../http";
import { NotesTab } from "./NotesTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
    minWidth: "500px",
    maxWidth: "500px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

const useStylesChip = makeStyles({
  main: {
    display: "flex",
    // flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 1, padding: 0, height: "22px", fontSize: ".7rem" },
  important: {
    height: "22px",
    padding: 0,
    margin: 1,
    color: "white",
    backgroundColor: red[700],
    fontSize: ".7rem",
  },
});

function TopInfoTable({ record }) {
  const { order } = record;
  const classes = useStyles();

  const classesChip = useStylesChip();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Status
            </TableCell>
            <TableCell align="right">
              <Chip label={record.status_item} className={classesChip.chip} />

              {record.is_urgent && (
                <Chip className={classesChip.important} label="Pilne" />
              )}
              {record.is_return && (
                <Chip className={classesChip.chip} label="Zwrot" />
              )}
              {record.is_complaint && (
                <Chip className={classesChip.chip} label="Reklamacja" />
              )}

              {record.is_artist_address_note && (
                <Chip className={classes.chip} label="Adr. artysty" />
              )}
              {record.is_buyer_address_note && (
                <Chip className={classes.chip} label="Adr. klienta" />
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Status transportu
            </TableCell>
            <TableCell align="right">
              <Chip
                style={{
                  backgroundColor:
                    record.status_transport_raw === "none"
                      ? yellow[300]
                      : undefined,
                  color:
                    record.status_transport_raw === "none"
                      ? red[900]
                      : undefined,
                }}
                label={record.status_transport}
                className={classesChip.chip}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Faktura
            </TableCell>
            <TableCell align="right">
              {order.invoiced && (
                <>
                  <Button
                    style={{
                      fontSize: "10px",
                      padding: "4px 10px",
                      minWidth: "32px",
                    }}
                    color="primary"
                    size="small"
                    href={order.invoice_url}
                    target="_blank"
                  >
                    {order.invoice_number}
                  </Button>
                </>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const ReviewEdit = ({ onCancel, ...props }) => {
  // const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  //
  // const handleDateChange = (date) => {
  //     setSelectedDate(date);
  // };

  const controllerProps = useEditController(props);

  const classes = useStyles();
  const refresh = useRefresh();

  const classesChip = useStylesChip();
  const [value, setValue] = React.useState(0);
  const [switchLoading, setSwitchLoading] = React.useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!controllerProps.record) {
    return null;
  }
  const { record } = controllerProps;
  const { order, artist_data } = record;

  const handleSwitchChange = (event) => {
    const name = event.target.name;

    setSwitchLoading({ ...switchLoading, [name]: true });
    httpClient(`${apiURL}/orders/${record.id}?_method=PATCH`, {
      method: "POST",
      body: JSON.stringify([
        { op: "replace", value: event.target.checked, path: `/${name}` },
      ]),
    })
      .then(() => {
        refresh();
        setSwitchLoading({ ...switchLoading, [name]: false });
      })
      .catch((e) => {
        setSwitchLoading({ ...switchLoading, [name]: false });
        showNotificationAction(e, "error");
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {record["order_item_number"]} (#{record.id})
        {!record.order.user_id && (
          <Chip
            style={{ backgroundColor: red[300] }}
            label="Zakup bez rejestracji"
            color="secondary"
          />
        )}
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <Container>
        <Box>
          <Box style={{ float: "left", margin: "0 10px" }}>
            <a
              href={`https://www.gallerystore.pl${controllerProps.record.work.canonical_url}`}
              target="_blank"
            >
              <img
                alt={controllerProps.record["name"]}
                src={controllerProps.record["thumbinal"]}
              />
            </a>
          </Box>
          <Box>
            <span style={{ fontSize: ".75rem" }}>{record["work_author"]}</span>
            <br />
            <a
              style={{ fontSize: ".65rem" }}
              href={`https://www.gallerystore.pl${controllerProps.record.work.canonical_url}`}
              target="_blank"
            >
              {record["work_name"]} ({record["work_dimensions"]})
            </a>
            <br />
            <br />
          </Box>
        </Box>
      </Container>
      <Container>
        <DrawerToolbar record={record} />
      </Container>
      <Paper square>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Info" />
          <Tab label="Dostawa" />
          <Tab
            style={{ color: record["has_notes"] ? "red" : undefined }}
            label="Notatki"
          />
        </Tabs>
      </Paper>

      <TabPanel value={value} index={0}>
        <TopInfoTable record={record} />
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Data przelewu (od klienta)
                </TableCell>
                <TableCell align="right">
                  <TextField record={record} source="payment_date_ack" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Data przelewu (dla artysty)
                </TableCell>
                <TableCell align="right">
                  <TextField record={record} source="mbank_export_date" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Odbiór przesyłki od artysty
                </TableCell>
                <TableCell align="right">
                  {record.transport && (
                    <>{record.transport.author_book_date || "-"}</>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Odbiór przesyłki dla klienta
                </TableCell>
                <TableCell align="right">
                  {record.transport && (
                    <>{record.transport.buyer_book_date || "-"}</>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Data dostarczenia przesyłki do klienta
                </TableCell>
                <TableCell align="right">
                  {record.transport && (
                    <>{record.transport.buyer_transport_done_date || "-"}</>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Rodzaj działalności
                </TableCell>
                <TableCell align="right">
                  {record.tax_status_label}
                  {record.artist_invoice_ack &&
                    record.tax_status === "company" && (
                      <strong style={{ color: green[700] }}>
                        {" "}
                        (faktura OK)
                      </strong>
                    )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Metoda płatności
                </TableCell>
                <TableCell align="right">
                  <TextField source="order.payment_method" record={record} />
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell component="th" scope="row">
                  Cena sprzedaży
                </TableCell>
                <TableCell align="right">
                  {/*- edytowana na czerwono jak zmieniana*/}

                  <TextField source="sold_price" record={record} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Cena autorska
                </TableCell>
                <TableCell align="right">
                  {/*(ukryta)  edytowana na czerwono jak zmieniana*/}

                  <TextField source="author_price" record={record} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Kwota do wypłaty
                </TableCell>
                <TableCell align="right">
                  {/*edytowana na czerwono jak zmieniana*/}
                  <TextField source="artist_payment_fee" record={record} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  PPP
                </TableCell>
                <TableCell align="right">
                  {record.in_partner_program ? 'tak' : 'nie'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Wartość zamówienia przed rabatem
                </TableCell>
                <TableCell align="right">
                  {order.real_price} {order.currency}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Wartość zamówienia
                </TableCell>
                <TableCell align="right">
                  {order.order_price} {order.currency}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Promocja
                </TableCell>
                <TableCell align="right">
                  <TextField source="discount_name" record={record} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <TopInfoTable record={record} />
        <Card>
          <CardContent style={{ fontSize: "0.7rem" }}>
            <Typography color="textSecondary" gutterBottom>
              Dane artysty
            </Typography>
            <strong>{artist_data.name}</strong>
            <br />
            {artist_data.street}{" "}
            {[artist_data.houseNumber, artist_data.apartmentNumber]
              .filter((item) => !!item)
              .join(" / ")}
            <br />
            <strong>{artist_data.zip}</strong> {artist_data.city}
            <br />
            <strong>Tel: {artist_data.phone}</strong>
            <br />
            <strong>E-mail: {artist_data.email}</strong>
            <br />
            <strong>Osoba kontaktowa: {artist_data.person}</strong>
            <br />
          </CardContent>
        </Card>

        <Card>
          <CardContent style={{ fontSize: "0.7rem" }}>
            <Typography color="textSecondary" gutterBottom>
              Dane klienta
              {order.main_company && (
                <Chip label="Firma" style={classesChip.chip} />
              )}
            </Typography>
            {order.main_company && (
              <>
                <strong>{order.main_companyname}</strong>
                <br />
              </>
            )}
            {order.taxid && (
              <>
                NIP: <strong>{order.taxid}</strong>
                <br />
              </>
            )}
            <strong>
              {order.main_firstname} {order.main_lastname}
            </strong>
            <br />
            {order.main_address}{" "}
            {[order.main_house_number, order.main_apartment_number]
              .filter((item) => !!item)
              .join(" / ")}
            <br />
            <strong>{order.main_zip}</strong> {order.main_city} (
            {order.main_country})<br />
            <strong>
              Tel: {order.main_phonecountrycode} {order.main_phonenumber}
            </strong>
            <br />
            <strong>E-mail: {order.main_email}</strong>
            <br />
          </CardContent>
        </Card>

        <Card>
          <CardContent style={{ fontSize: "0.7rem" }}>
            <Typography color="textSecondary" gutterBottom>
              Adres dostawy
            </Typography>

            {order.oth_address ? (
              <>
                <strong>
                  {order.oth_firstname} {order.oth_lastname}
                </strong>
                <br />
                {order.oth_address}{" "}
                {[order.oth_house_number, order.oth_apartment_number]
                  .filter((item) => !!item)
                  .join(" / ")}
                <br />
                <strong>{order.oth_zip}</strong> {order.oth_city} (
                {order.oth_country})<br />
                <strong>
                  Tel: {order.oth_phonecountrycode} {order.oth_phonenumber}
                </strong>
                <br />
              </>
            ) : (
              <>
                <strong>
                  {order.main_firstname} {order.main_lastname}
                </strong>
                <br />
                {order.main_address}{" "}
                {[order.main_house_number, order.main_apartment_number]
                    .filter((item) => !!item)
                    .join(" / ")}
                <br />
                <strong>{order.main_zip}</strong> {order.main_city} (
                {order.main_country})<br />
                <strong>
                  Tel: {order.main_phonecountrycode} {order.main_phonenumber}
                </strong>
                <br />
              </>
            )}
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <NotesTab
          switchLoading={switchLoading}
          record={record}
          onChange={handleSwitchChange}
          controllerProps={controllerProps}
        />
      </TabPanel>
    </div>
  );
};

export default ReviewEdit;

import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  Toolbar,
  SaveButton,
  showNotification as showNotificationAction,
} from "react-admin";
import { Api } from "../http";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { map } from "lodash";
import { useCallback, useState } from "react";
import { useCreate, useRedirect, useNotify } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Zapisz" redirect="settings" submitOnEnter={false} />
    </Toolbar>
  );
};

function useSettings(prefix) {
  const [loading, setLoading] = React.useState(true);
  const [values, setValues] = React.useState({});
  React.useLayoutEffect(() => {
    Api.getSettings().then((response) => {
      setLoading(false);

      const data = {};
      response.json.forEach((item) => {
        if (item.name.indexOf(prefix) === 0) {
          data[item.name] = item.value;
        }
      });
      setValues(data);
    });
  }, []);

  return [loading, values];
}
export default function Settings() {
  const [settingsLoading, initialValues] = useSettings("transport_");
  const [settingsSaving, setSettingsSaving] = useState(false);

  const redirectTo = useRedirect();
  const notify = useNotify();
  const handleSave = useCallback(
    (values) => {
      const operations = map(values, (value, key) => ({
        op: "replace",
        value,
        path: `/${key}`,
      }));

      Api.patchSettings(operations)
        .then(() => {
          setSettingsSaving(false);
        })
        .catch((e) => {
          setSettingsSaving(false);
          notify(e.message);
        });
    },
    [notify, redirectTo]
  );

  const classes = useStyles();
  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={settingsLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <h1>DHL Dane Adresowe</h1>
      <SimpleForm
        save={handleSave}
        saving={settingsLoading || settingsSaving}
        initialValues={initialValues}
        warnWhenUnsavedChanges
        toolbar={<PostCreateToolbar />}
      >
        <TextInput
          fullWidth
          source="transport_shipping_name"
          label="Nazwa nadawcy"
        />
        <TextInput fullWidth source="transport_shipping_postal" />
        <TextInput fullWidth source="transport_shipping_city" />
        <TextInput fullWidth source="transport_shipping_street" />
        <TextInput fullWidth source="transport_shipping_houseNumber" />
        <TextInput fullWidth source="transport_shipping_apartmentNumber" />
        <TextInput fullWidth source="transport_shipping_contactEmail" />
        <TextInput fullWidth source="transport_shipping_contactPerson" />
        <TextInput fullWidth source="transport_shipping_contactPhone" />
        <TextInput fullWidth source="transport_package_content" />
      </SimpleForm>
    </>
  );
}

// in src/App.js
import React, { useCallback, useState } from "react";
import jsonServerProvider from "ra-data-json-server";
import { withStyles } from '@material-ui/core/styles';
import env from "./env"
import {
    Admin,
    BooleanField,
    Datagrid,
    DateInput,
    Filter,
    List,
    Pagination,
    Resource,
    TextInput, usePermissions,
    useUnselectAll,
} from "react-admin";
import { Drawer, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { Route, useHistory } from "react-router-dom";
import OrderDrawer from "./Orders/OrderDrawer";
import StatusesField from "./Fields/StatusesField";
import Box from "@material-ui/core/Box";
import { red, green } from "@material-ui/core/colors";
import DoneIcon from "@material-ui/icons/Done";
import polishMessages from "ra-language-polish";
import authProvider from "./authProvider";
import customRoutes from "./customRoutes";
import Layout from "./Containers/Layout";
import { apiURL, httpClient } from "./http";
import rowStyler from "./rowStyler";
import BulkArtistTransportButton from "./Components/BulkArtistTransportButton";
import BulkBuyerTransportButton from "./Components/BulkBuyerTransportButton";
import BulkExportMbankButton from "./Components/BulkExportMbankButton";
import { truncate } from "lodash";
import { ActionTabs } from "./Containers/ActionTabs";
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const PostBulkActionButtons = (props) => (
  <>
    {props.tab === "transport_artist" && (
        <>
          <BulkArtistTransportButton label="Kurier Artysta" {...props} />
        </>
    )}

      {props.tab === "transport_buyer" && (
          <BulkBuyerTransportButton label="Kurier Klient" {...props} />
      )}
      {props.tab === "to_bank" && (
          <BulkExportMbankButton label="Eksportuj mBank" {...props} />
      )}

    {/* default bulk delete action */}
    {/*<BulkDeleteButton {...props} />*/}
  </>
);

const ListFilters = (props) => (
  <Filter {...props}>
    <DateInput source="date_from" alwaysOn />
    <DateInput source="date_to" alwaysOn />
    <TextInput source="search" alwaysOn />
  </Filter>
);

const messages = {
  pl: polishMessages,
};

const BuyerField = ({ record }) => {
  const { order } = record;
  return (
    <>
      <span style={{ fontSize: ".75rem" }}>{record["buyer"]}</span>
      <br />
      <small>
        {" "}
        {order.oth_address ? (
          <>
            <span
              style={{
                color:
                  order.oth_country.toUpperCase() !== "PL"
                    ? red[500]
                    : undefined,
              }}
            >
              {order.oth_country.toUpperCase()}
            </span>
            , {order.oth_city}
          </>
        ) : (
          <>
            <span
              style={{
                color:
                  order.main_country.toUpperCase() !== "PL"
                    ? red[500]
                    : undefined,
              }}
            >
              {order.main_country.toUpperCase()}
            </span>
            , {order.main_city}
          </>
        )}
      </small>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

const OrderFieldContents = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return <div {...props} ref={ref}>
        <div
            style={{
                fontSize: ".75rem",
                minWidth: "110px",
                color: props.record["has_notes"] ? "#a62100" : undefined,
            }}
        >
            {props.record["order_item_number"]} [{props.record.order.items_count}]
        </div>
        <small
            style={{
                fontSize: ".65rem",
                minWidth: "110px",
                color: props.record["has_notes"] ? "#a62100" : undefined,
            }}
        >
            {props.record["order_item_date"]}
        </small>


    </div>
});


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const OrderField = ({ record }) => {
    if (record.has_notes) {
        return (
            <HtmlTooltip title={(<>
                <p style={{color: red[800], fontStyle: "italic"}}>{record.private_notes}</p>
                <p style={{color: green[800]}}>{record.order.info}</p>
            </>)}>
                <OrderFieldContents record={record}/>
            </HtmlTooltip>
        );
    }
    return (<OrderFieldContents record={record}/>);
}

const ImageField = ({ record, source }) => (
  <img alt={record["name"]} src={record["thumbinal"]} />
);

const InvoiceField = ({ record, source }) => {
  if (record.is_company) {
    return record[source] ? <DoneIcon style={{ color: green[500] }} /> : <FiberManualRecordIcon style={{ color: red[500] }} />;
  }
  return <></>;
};
const PayoutField = ({ record, source }) => (
  <>
    <span>{record["sold_price"]}</span>
    <br />
    <small>
      {record[source]} ({record.is_company ? "D" : "K"})
    </small>
  </>
);

const WorkField = ({ record, source }) => (
  <>
    <Box component="div" style={{ fontSize: ".75rem" }} textOverflow="ellipsis">
      {record["work_author"]}
    </Box>
    <Box component="div" textOverflow="ellipsis" style={{ fontSize: ".65rem" }}>
      {truncate(record["work_name"], {
        length: 20,
      })}{" "}

      <span style={record['work_is_large'] ? { color: red[500] } : {}}>({record["work_dimensions"]})</span>
    </Box>
  </>
);

const dataProvider = jsonServerProvider(apiURL, httpClient);
const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100]} {...props} />
);

export const UserList = (props) => {
  const classes = useStyles();
  const history = useHistory();
    const { permissions } = usePermissions();

    const isAdmin = permissions && permissions.includes("ROLE_ADMIN");

  const [tab, setTab] = useState(isAdmin ? "active":"transport_artist");
  const [filter, setFilter] = useState(isAdmin ? { tab: "active" }:{ tab: "ready_for_artist_transport" });
  const handleClose = useCallback(() => {
    history.push("/orders");
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/orders/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );

          return (
            <>
              <List
                perPage={50}
                bulkActionButtons={<PostBulkActionButtons tab={tab} />}
                pagination={<PostPagination />}
                filters={<ListFilters />}
                actions={
                  <ActionTabs tab={tab} setTab={setTab} setFilter={setFilter} />
                }
                dense
                filter={filter}
                {...props}
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
              >
                <Datagrid
                  rowClick="edit"
                  rowStyle={rowStyler}
                  selectedRow={props.selectedRow}
                >
                  <OrderField label="Zamówienie" />
                  <ImageField label="" />
                  <WorkField label="Praca" />

                  <BuyerField label="Klient" />
                  <StatusesField label="Status" />
                  {/*<TextField source="payment_method" label="Transport/ data nadania"/>*/}
                  {/*<TextField source="payment_method" label="Płatność"/>*/}

                  {/*edytowana na czerwono jak zmieniana*/}
                  <PayoutField
                    source="artist_payment_fee"
                    label="Wypłata / Sprzedaż"
                  />

                  {/*edytowana na czerwono jak zmieniana*/}
                  <BooleanField
                    source="is_author_payment_done"
                    label="Przelew artysta"
                  />
                  {/*ptaszek zrobiony, do zapłaty dolar - minie ileś dni, x - czeka*/}
                  <InvoiceField source="artist_invoice_ack" label="FV" />
                </Datagrid>
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch ? (
                  <OrderDrawer
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </>
          );
        }}
      </Route>
    </div>
  );
};

const App = () => (
    <>
  <Admin
    layout={Layout}
    customRoutes={customRoutes}
    authProvider={authProvider}
    dataProvider={dataProvider}
    locale="pl"
    title="GalleryStore Admin"
    messages={messages}
  >
    <Resource name="orders" options={{ label: "Zamówienia" }} list={UserList} />
  </Admin>
        </>

);

export default App;

import * as React from "react";
import { Route } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Reports from "./Containers/Reports";
import Settings from "./Containers/Settings";
import Statuses from "./Containers/Statuses";

export default [
  <Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/reports" component={Reports} />,
  <Route exact path="/settings" component={Settings} />,
  <Route exact path="/statuses" component={Statuses} />,
];

import {
  fetchUtils,
  showNotification as showNotificationAction,
} from "react-admin";
import env from "./env"
export const apiURL = env.apiurl;

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Basic ${token}`);
  options.headers.set("X-Sec-Token", "fab10dee-7260-4e66-bdc1-c98e38749daf")
  return fetchUtils.fetchJson(url, options);
};

const getSettings = () => {
  return httpClient(`${apiURL}/settings`, {
    method: "GET",
  });
};

const getDashboard = () => {
  return httpClient(`${apiURL}/dashboard`, {
    method: "GET",
  });
};

const patchSettings = (operations) => {
  return httpClient(`${apiURL}/settings`, {
    method: "PATCH",
    body: JSON.stringify(operations),
  });
};
const patchOrderItem = (orderItemId, operations) => {
  return httpClient(`${apiURL}/orders/${orderItemId}`, {
    method: "PATCH",
    body: JSON.stringify(operations),
  });
};
export const Api = {
  getSettings,
  getDashboard,
  patchSettings,
  patchOrderItem,
};

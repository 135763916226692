import * as React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core";
import { red, yellow, green, orange, pink } from "@material-ui/core/colors";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 1, padding: 0, height: "22px", fontSize: ".7rem" },
  important: {
    height: "22px",
    padding: 0,
    margin: 1,
    color: "white",
    backgroundColor: red[700],
    fontSize: ".7rem",
  },
  new: {
    height: "22px",
    padding: 0,
    margin: 1,
    color: "white",
    backgroundColor: red[200],
    fontSize: ".7rem",
  },
  ended: {
    height: "22px",
    padding: 0,
    margin: 1,
    color: "black",
    backgroundColor: orange[300],
    fontSize: ".7rem",
  },
  buyer_transport_done: {
    height: "22px",
    padding: 0,
    margin: 1,
    color: "black",
    backgroundColor: green[200],
    fontSize: ".7rem",
  },
  canceled: {
    height: "22px",
    padding: 0,
    margin: 1,
    color: "white",
    backgroundColor: red[900],
    fontSize: ".7rem",
  },
  pink: {
    height: "22px",
    padding: 0,
    margin: 1,
    color: "white",
    backgroundColor: pink[200],
    fontSize: ".7rem",
  },
});

const StatusesField = ({ record }) => {
  const classes = useStyles();

  const getClass = () => {

    if (record.status_item_raw === "ended") {
      return classes.ended;
    }

    if (record.status_item_raw === "new") {
      return classes.new;
    }
    if (record.status_item_raw === "canceled" || record.status_item_raw === "correction") {
      return classes.canceled;
    }

    return classes.chip;
  };
  const getClassT = () => {

    if (record.status_transport_raw === "buyer_transport_done" || record.status_transport_raw === "buyer_transport_manual") {
      return classes.buyer_transport_done;
    }
    return classes.chip;
  };

  return record ? (
    <span className={classes.main}>
      <Chip className={getClass()} label={record.status_item} />
      <Chip className={record.order.payment_method!=="Pobranie" ? classes.chip : classes.pink} label={record.order.payment_method} />





      <Chip
        className={getClassT()}
        style={{
          backgroundColor:
            record.status_transport_raw === "none" ? yellow[300] : undefined,
          color: record.status_transport_raw === "none" ? red[900] : undefined,
        }}
        label={record.status_transport}
      />

      {record.mark_is_deadline && <Chip className={classes.important} label="Termin" />}
      {record.mark_is_other && <Chip className={classes.important} label="Inne" />}
      {record.is_urgent && <Chip className={classes.important} label="Pilne" />}
      {record.is_return && <Chip className={classes.important} label="Zwrot" />}
      {record.is_complaint && (
        <Chip className={classes.important} label="Reklamacja" />
      )}
      {record.is_artist_address_note && (
        <Chip className={classes.important} label="Adr. artysty" />
      )}
      {record.is_buyer_address_note && (
        <Chip className={classes.important} label="Adr. nabywcy" />
      )}
    </span>
  ) : null;
};

StatusesField.defaultProps = {
  addLabel: true,
  source: "groups",
};

export default StatusesField;

import * as PropTypes from "prop-types";
import React from "react";

import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import tabs from "../_tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import BankExportButton from "../Orders/BankExportButton";
import { Divider } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {useUnselectAll, useGetList, usePermissions} from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
function TabButton({ tooltip, selected, onClick, name }) {
  return (
    <Tooltip title={tooltip}>
      <Button
        style={{
          fontSize: "11px",
          padding: "3px 0px",
        }}
        variant={selected ? "contained" : undefined}
        onClick={onClick}
      >
        {name}
      </Button>
    </Tooltip>
  );
}

export function ActionTabs({ tab, setTab, setFilter }) {
  const classes = useStyles();
    const unselect = useUnselectAll("orders");
    const { permissions } = usePermissions();

    const showTabs1 = permissions && permissions.includes("ROLE_ADMIN");
    const showTabs2 = true;


    const tabs1 = Object.values(tabs).filter((item) => item.row === 2);
    const tabs2 = Object.values(tabs).filter((item) => item.row === 1);
  return (
    <Box>
      <div className={classes.root}>
          <Grid container spacing={0}>
          
          <Grid item xs={12}>
            <ButtonGroup size="small" aria-label="small outlined button group">
              {tabs1.filter((tab)=> showTabs1 || tab.id === 'new').map((choice) => (
                <TabButton
                  key={choice.id}
                  tooltip={choice.tooltip}
                  name={choice.name}
                  selected={choice.id === tab}
                  onClick={() => {
                      unselect();
                    setTab(choice.id);
                    setFilter(tabs[choice.id].filter);
                  }}
                />
              ))}
            </ButtonGroup>
            {showTabs1 && (<BankExportButton/>)}
          
          </Grid>

              {showTabs2 && (<Grid item xs={12}>
            <ButtonGroup size="small" aria-label="small outlined button group">
              {tabs2.map((choice) => (
                <TabButton
                  key={choice.id}
                  tooltip={choice.tooltip}
                  name={choice.name}
                  selected={choice.id === tab}
                  onClick={() => {
                    setTab(choice.id);
                    setFilter(tabs[choice.id].filter);
                  }}
                />
              ))}
            </ButtonGroup>
          </Grid>)}
        </Grid>
      </div>

      <Divider />
    </Box>
  );
}

import * as React from "react";
import MuiToolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import SetPaymentDateButton from "./SetPaymentDateButton";
import SetManualTransportDateButton from "./SetManualTransportDateButton.jsx";
import SendTransportToArtistButton from "./SendTransportToArtistButton";
import DhlCheckButton from "./DhlCheckButton";
import OrderStatusButton from "../Components/OrderStatusButton.jsx";
import SendTransportToBuyerButton from "./SendTransportToBuyerButton";
import ConfirmInvoiceButton from "./ConfirmInvoiceButton";
import SendToBankExportButton from "./SendToBankExportButton";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-between",
  },
}));

const ReviewEditToolbar = ({ record }) => {
  const classes = useStyles();

  if (!record) return null;
  return (
    <MuiToolbar className={classes.root}>
      {record.actions.map((action) => {
        if (action.id === "set_payment_date") {
          return <SetPaymentDateButton record={record} action={action} />;
        }
        if (action.id === "send_transport_to_artist") {
          return (
            <SendTransportToArtistButton record={record} action={action} />
          );
      }

        if (
          [
            "set_status_payed",
            "set_status_canceled",
            "set_status_ended",
            "set_status_correction",
          ].includes(action.id)
        ) {
          return (
            <OrderStatusButton
              type="status_item"
              status={action.status}
              record={record}
              action={action}
            />
          );
        }

        if (
          ["send_transport_to_artist_manual"].includes(
            action.id
          )
        ) {
          return (
            <OrderStatusButton
              type="status_transport"
              status={action.status}
              record={record}
              action={action}
            />
          );
        }


        if (
            ["send_transport_manual", "send_transport_done"].includes(
                action.id
            )
        ) {
          return (
              <SetManualTransportDateButton
                  status={action.status}
                  record={record}
                  action={action}
              />
          );
        }


        if (action.id === "send_transport_to_buyer") {
          return <SendTransportToBuyerButton record={record} action={action} />;
        }
        if (action.id === "dhl_check") {
          return <DhlCheckButton record={record} action={action} />;
        }

        if (action.id === "pay_artist_waiting") {
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={true}
              style={{
                fontSize: "10px",
                padding: "4px 10px",
                minWidth: "32px",
              }}
            >
              {action.label}
            </Button>
          );
        }

        if (action.id === "pay_artist_mbank") {
          return (
            <SendToBankExportButton
              variant="contained"
              color="primary"
              size="small"
              record={record}
              action={action}
            />
          );
        }

        return null;
      })}

      {!record.artist_invoice_ack && record.tax_status === "company" && (
        <ConfirmInvoiceButton record={record} />
      )}
    </MuiToolbar>
  );
};

export default ReviewEditToolbar;

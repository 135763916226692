import * as React from "react";
import {
  SimpleForm,
  Toolbar,
  Button,
  SaveButton,
  useRefresh,
  useRedirect,
  useNotify,
  TextInput,
} from "react-admin";

import { Api } from "../http";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { map } from "lodash";
import { useCallback, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PostCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Zapisz" redirect="settings" submitOnEnter={false} />
    </Toolbar>
  );
};

export default function PrivateNotesForm({ orderItemId, initialValues }) {
  const [settingsSaving, setSettingsSaving] = useState(false);

  const redirectTo = useRefresh();
  const notify = useNotify();

  const handleSave = useCallback(
    (values) => {
      setSettingsSaving(true);
      const operations = map(values, (value, key) => ({
        op: "replace",
        value,
        path: `/${key}`,
      }));

      Api.patchOrderItem(orderItemId, operations)
        .then(() => {
          setSettingsSaving(false);
        })
        .catch((e) => {
          setSettingsSaving(false);
          notify(e.message);
        });
    },
    [notify, redirectTo]
  );

  const classes = useStyles();

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={settingsSaving}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SimpleForm
        save={handleSave}
        saving={settingsSaving}
        initialValues={initialValues}
        warnWhenUnsavedChanges
        toolbar={<PostCreateToolbar />}
      >
        <TextInput
          multiline
          fullWidth
          source="private_notes"
          label="Notatki wewnętrzne"
        />
      </SimpleForm>
    </>
  );
}

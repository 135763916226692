import * as React from "react";
import Button from "@material-ui/core/Button";
import CalendarIcon from "@material-ui/icons/CalendarViewDaySharp";
import {
  showNotification as showNotificationAction,
  useRefresh,
  fetchUtils,
  useNotify,
} from "react-admin";
import { apiURL, httpClient } from "../http";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import { saveAs } from "file-saver";
import moment from "moment";

export default function SetPaymentDateButton({ action, record }) {
  const [clicked, setClicked] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  return (
    <>
      {!clicked && (
        <Button
          style={{
            fontSize: "10px",
            padding: "4px 10px",
            minWidth: "32px",
            marginLeft: "10px",
            color: "white",
            backgroundColor: red[700],
          }}
          size="small"
          variant="contained"
          onClick={() => {
            setClicked(true);
            httpClient(`${apiURL}/export/bank`, {
              method: "GET",
            })
              .then((response) => {
               const blob = new Blob([response.body], {
                  type: "text/plain;charset=utf-8",
                });


                const date = moment().format("YYYYMMDDHHmmss")

                saveAs(blob, `mbank-export-${date}.txt`);

                refresh();
                setClicked(false);
              })
              .catch((e) => {
                if(e.status === 404) {
                  notify("Brak zamówień do eksportu");
                }
                else {
                  notify(e?.body?.detail || "Błąd");
                }

                setClicked(false);
              });
          }}
          disabled={false}
        >
          mBank
        </Button>
      )}
      {clicked && <CircularProgress size={16} />}
    </>
  );
}

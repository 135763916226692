import * as React from "react";
import Button from "@material-ui/core/Button";
import CalendarIcon from "@material-ui/icons/CalendarViewDaySharp";
import {
    showNotification as showNotificationAction,
    useRefresh,
    fetchUtils,
} from "react-admin";
import { apiURL,  Api } from "../http";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red, green, yellow, blue } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";

export default function SetPaymentDateButton({ action, record, status, type }) {
    const [clicked, setClicked] = React.useState(false);
    const refresh = useRefresh();

    const color = {
        ended: red[700],
        canceled: red[700],
        correction: red[700],
    };
    return (
        <>
            {!clicked && (
                <Tooltip title={action.tooltip}>
                    <Button
                        style={{
                            fontSize: "10px",
                            padding: "4px 10px",
                            minWidth: "32px",
                            color: "white",
                            backgroundColor: color[status] || undefined,
                        }}
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => {
                            setClicked(true);
                            // eslint-disable-next-line no-restricted-globals
                            if (action.confirm && !confirm(`Czy chcesz wykonać "${action.label}" ?`)) {
                                setClicked(false);
                                return;
                            }
                            Api.patchOrderItem(record.id, [
                                { op: "replace", value: status, path: "/" + type },
                            ])
                                .then(() => {
                                    refresh();
                                    setClicked(false);
                                })
                                .catch((e) => {
                                    showNotificationAction(e, "error");
                                    setClicked(false);
                                });
                        }}
                        disabled={false}
                    >
                        {action.label}
                    </Button>
                </Tooltip>
            )}
            {clicked && <CircularProgress size={16} />}
        </>
    );
}

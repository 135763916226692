const rowStyler = (record) => {
  // jesli sortowanie ASC to dać borderTop
  if (record.item_pos === 1) {
    return {
      borderBottomColor: "#000",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
    };
  }
  return {};
};

export default rowStyler;

import * as React from "react";
import Button from "@material-ui/core/Button";
import CalendarIcon from "@material-ui/icons/CalendarViewDaySharp";
import {
  showNotification as showNotificationAction,
  useRefresh,
  fetchUtils,
  useNotify,
} from "react-admin";
import { apiURL, httpClient } from "../http";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

export default function SetPaymentDateButton({ action, record }) {
  const [clicked, setClicked] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  return (
    <>
      {!clicked && (
        <Tooltip title={action.tooltip}>
          <Button
            style={{
              fontSize: "10px",
              padding: "4px 10px",
              minWidth: "32px",
            }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setClicked(true);
              httpClient(
                `${apiURL}/orders/${record.id}/send_transport_to_artist`,
                {
                  method: "POST",
                  body: JSON.stringify({}),
                }
              )
                .then(() => {
                  refresh();
                  setClicked(false);
                })
                .catch((e) => {
                  notify(e?.body?.detail || "Błąd");
                  setClicked(false);
                });
            }}
            disabled={false}
          >
            {action.label}
          </Button>
        </Tooltip>
      )}
      {clicked && <CircularProgress size={16} />}
    </>
  );
}

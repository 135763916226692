import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Settings() {
  const classes = useStyles();
  const statuses = [
    "Nowe",
    "Opłacone ",
    "Zaksięgowane",
    "Zapłać artyście",
    "Zapłacono artyście",
    "Zakończone",
    "Anulowane",
    "Korekta",
  ];

  const statusesTransport = [
    "Nie rozpoczęty",
    "Artysta - e-mail",
    "Artysta - zamówił",
    "Artysta - w drodze",
    "Artysta - odebrano",
    "Artysta - ręcznie",

    "Klient - zamówiono",
    "Klient - w drodze",
    "Klient - dostarczono",
    "Klient - wysłano ręcznie",
  ];

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h1>Statusy zamówienia</h1>

            <Timeline>
              {statuses.map((name, index) => (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    {index !== statuses.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>{name}</TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
          <Grid item xs={6}>
            <h1>Statusy transportu</h1>
            <Timeline>
              {statusesTransport.map((name, index) => (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    {index !== statusesTransport.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>{name}</TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import React, {
    FC,
} from 'react';

import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import {Api} from "../http";
import env from "../env";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

interface OrderStats {
    revenue: number;
    nbNewOrders: number;
}


const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em', marginTop: '1em', },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em', color: 'gray' },
};

const Spacer = () => <span style={{ width: '1em' }} />;

const Dashboard: FC = () => {

const classes = useStyles();
const [loading,setLoading] = React.useState(true);

interface DashboardResponse {
    revenue?: string
    orders?: string
}
const [data,setData] = React.useState<DashboardResponse>({});

    React.useLayoutEffect(() => {
        Api.getDashboard().then((response:any) => {
            setLoading(false);
            setData(response.json);
        });
    }, []);

    return (
        <>
            <Backdrop
                className={classes.backdrop}
                open={loading}
                onClick={() => {}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <MonthlyRevenue value={data.revenue || ""} />
                        <Spacer />
                        <NbNewOrders value={data.orders || ""} />
                    </div>
                    <div style={styles.singleCol}>
                        <small>
                            <div>Wersja: {env.release}</div>
                        </small>

                    </div>


                </div>


            </div>


        </>
    );
};

export default Dashboard;
